/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%;
}

.app-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 70px;
  width: 100%;


  @media screen and (max-width: 575px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 70px;
  }
}