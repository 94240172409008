.btn-filters button {
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 40px;
}

#add-fab-button {
  position: fixed;
  right: 30px;
  bottom: 70px;
}

.container-list {
  margin-bottom: 30px;
}

  .full-width {
    width: 100%
  }

.eventModal {
  width: 500px;
  
  .MuiSelect-select {
    text-align: left;
  }
  .modal-box-header {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 15px;
  }
  .modal-box-footer {
    position: relative;
    left: 125px;
  }
}

@media screen and (min-width: 991px) {
  .modal-user {
    max-width: 1000px;
  }
}